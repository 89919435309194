<template>
    <div class="">
        <div class="history_title">
            {{ $t('history_title') }}
            <img class="main_logo" src="@/assets/image/main/result_logo.png">
        </div>
        <div class="h_top_content">
            <table class="h_t_table" width="100%" border="0" cellspacing="0" cellpadding="0"
                   style="table-layout: auto; width: 1200px; table-layout: fixed;">
                <colgroup>
                    <col width="300px"/>
                    <col width="300px"/>
                    <col width="300px"/>
                    <col width="300px"/>
                </colgroup>
                <tr class="title_row">
                    <th>{{ $t('n_history') }}</th>
                    <th>{{ $t('d_history') }}</th>
                    <th>{{ $t('t_history') }}</th>
                    <th>{{ $t('market') }}</th>
                </tr>
                <tr class="table_content">
                    <td class="roboto_font">{{ historyData.Buyer.pm_order }}</td>
                    <td class="roboto_font">{{ historyData.Buyer.created_at }}</td>
                    <td>{{ checkSellType(historyData.Buyer.pm_type) }}</td>
                    <td>{{ checkMarket(historyData.Buyer.pm_status) }}</td>
                </tr>
            </table>


        </div>

        <div class="h_list_content">
            <table class="h_t_table" width="100%" border="0" cellspacing="0" cellpadding="0"
                   style="table-layout: auto; width: 1200px; table-layout: fixed;">
                <colgroup>
                    <col width="140px"/>
                    <col width="260px"/>
                    <col width="140px"/>
                    <col width="260px"/>
                    <col width="140px"/>
                    <col width="260px"/>
                </colgroup>
                <tr class="history_name_title">
                    <th colspan="6">
                        <span class="eng">{{ historyData.m_bid_nick }}</span> <span class="kor">{{ $t('sir') }}</span>
                    </th>
                </tr>
                <tr class="title_row">
                    <th colspan="2">{{ $t('r_history') }}</th>
                    <th colspan="2">{{ $t('item') }}</th>
                    <th colspan="2">{{ $t('a_history') }}</th>
                </tr>
                <tr>
                    <td>{{ $t('seller') }}</td>
                    <td>{{ historyData.m_nick }}</td>
                    <td>{{ $t('i_name') }}</td>
                    <td>{{ historyData.i_name }}</td>
                    <td>{{ $t('t_amount') }}</td>
                    <td>{{ historyData.m_bid_price }}Mg</td>
                </tr>
                <tr>
                    <td>{{ $t('buyer') }}</td>
                    <td>{{ historyData.m_bid_nick }}</td>
                    <td>{{ $t('i_num') }}</td>
                    <td class="roboto_font">{{ historyData.i_sku }}</td>
                    <td>{{ $t('s_tax') }}</td>
                    <td>{{ returnFees(historyData) }}</td>
                </tr>
                <tr>
                    <td v-if="checkCurator(historyData)">{{ $t('curator') }}</td>
                    <td v-else>-</td>
                    <td v-if="checkCurator(historyData)">{{ historyData.m_curator_nick }}</td>
                    <td v-else>-</td>

                    <td>{{ $t('type') }}</td>
                    <td>{{ checkType(historyData) }}</td>
                    <td>{{ $t('f_income') }}</td>
                    <td>{{ returnPrice(historyData) }}Mg</td>
                </tr>
                <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ $t('category') }}</td>
                    <td>{{ returnCategoryName(historyData) }}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td colspan="6" class="bigo_font">{{ $t('bigo') }}</td>
                </tr>
                <tr class="fees">
                    <td colspan="6" class="fees_font">{{ returnBigo(historyData) }}</td>
                </tr>
            </table>
        </div>


    </div>
</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";

export default {
    name: "HistoryDetailBuyerLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        historyData: {}
    },
    data() {
        return {
            memberType: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        checkSellType(data) {
            if (data === 1) {
                return this.$t('ownership_buy')
            } else if (data === 2) {
                return this.$t('ownership_sell')
            } else if (data === 3) {
                return this.$t('license_sell')
            } else if (data === 4) {
                return this.$t('buy_license')
            }
        },
        checkMarket(data) {
            if (data === 0) {
                return this.$t('openMarket')
            } else if (data === 1) {
                return this.$t('premiumMarket')
            }
        },
        checkType(data) {
            if (data.i_type === 'real') {
                return this.$t('real')
            } else {
                return this.$t('digital')
            }
        },
        returnCategoryName(data) {
            if (data.node_id === 2) {
                return 'Art'
            } else if (data.node_id === 3) {
                return 'Photo'
            } else if (data.node_id === 4) {
                return 'Video'
            } else if (data.node_id === 5) {
                return 'Music'
            } else if (data.node_id === 9) {
                return 'Collectibles'
            }else if(data.node_id===178){
                return 'Prompt'
            }
        },
        checkCurator(data) {
            return !util.isEmpty(data.m_curator)
        },
        returnPrice(data) {
            return Number(data.Buyer.p_point) * -1;
        },
        returnFees(data) {
            if (util.isEmpty(data.Buyer.pm_fees)) {
                return '-'
            } else {
                let fees =  ( data.Buyer.pm_fees*100)/data.i_price;
                if (data.Buyer.pm_fees == 0) {
                    fees = 0;
                }
                return `+${data.Buyer.pm_fees}Mg (${fees}%)`
            }
        },
        returnBigo(data) {
            if (data.m_first_order === 1) {
                if (data.Seller.pm_status === 1) {
                    if (data.i_type === 'real') {
                        return this.$t('feesMsg4')
                    } else if (data.i_type == 'digital') {
                        return this.$t('feesMsg1')
                    }
                } else if (data.Seller.pm_status === 0) {
                    if (data.i_type === 'real') {
                        return this.$t('no3')
                    } else if (data.i_type == 'digital') {
                        return this.$t('no3')
                    }
                }
            } else if (data.m_first_order === 0) {
                if (data.Seller.pm_status === 1) {
                    if (data.i_type === 'real') {
                        return this.$t('feesMsg4')
                    } else if (data.i_type == 'digital') {
                        return this.$t('no3')
                    }
                } else if (data.Seller.pm_status === 0) {
                    if (data.i_type === 'real') {
                        return this.$t('feesMsg10')
                    } else if (data.i_type == 'digital') {
                        return this.$t('no3')
                    }
                }
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
