<template>
    <div class="hdd_wrap" id='hdd_wrap' v-if="checkType()">
        <history-detail-curator-layout :history-data="historyData"
                                       v-if="memberType === 'curator'"></history-detail-curator-layout>
        <history-detail-buyer-layout :history-data="historyData"
                                     v-if="memberType === 'buyer'"></history-detail-buyer-layout>
        <history-detail-seller-layout :history-data="historyData"
                                      v-if="memberType === 'seller'"></history-detail-seller-layout>
        <div class="h_bottom_content">
            <button class="print_button" @click="info_print()">
                {{ $t('print') }}
            </button>
        </div>
    </div>

</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";
import HistoryDetailCuratorLayout from "@/template/history/detail/HistoryDetailCuratorLayout";
import HistoryDetailBuyerLayout from "@/template/history/detail/HistoryDetailBuyerLayout";
import HistoryDetailSellerLayout from "@/template/history/detail/HistoryDetailSellerLayout";

export default {
    name: "HistoryDetailDefaultLayout",
    mixins: [],
    components: {HistoryDetailSellerLayout, HistoryDetailBuyerLayout, HistoryDetailCuratorLayout},
    inject: [],
    provide() {
        return {}
    },
    props: {
        historyData: {}
    },
    data() {
        return {
            memberType: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
        this.checkType()
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        checkType() {
            if (util.isEmpty(this.UserInfo)) {
                return
            }
            if (!util.isEmpty(this.historyData.m_curator)) {
                if (this.UserInfo.mb_no === this.historyData.m_curator) {
                    this.memberType = 'curator'
                    return true
                } else if (this.UserInfo.mb_no === this.historyData.m_bid_no) {
                    this.memberType = 'buyer';
                    return true;
                } else if (this.UserInfo.mb_no === this.historyData.m_no) {
                    this.memberType = 'seller';
                    return true;
                }
            } else {
                if (this.UserInfo.mb_no === this.historyData.m_no) {
                    this.memberType = 'seller';
                    return true
                } else if (this.UserInfo.mb_no === this.historyData.m_bid_no) {
                    this.memberType = 'buyer';
                    return true;
                }
            }
            return false
        },
        info_print() {
            let initBody = document.body
            let hiddenBtn = document.querySelector('.h_bottom_content');

            window.onbeforeprint = function () {
                hiddenBtn.style.display = "none";
                document.body = document.querySelector('.hdd_wrap');
            }
            window.onafterprint = function () {
                hiddenBtn.style.display = "block";
                document.body = initBody;
            }

            window.print();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>